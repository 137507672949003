import React from "react";

import { graphql } from "gatsby";
import BlockTextImage from "../components/BlockTextImage";

import Layout from "../components/layout.js";
import SEO from "../components/seo";
import TeammemberDetails from "../components/TeammemberDetails/index.js";

const UeberPage = ({ data }) => {
  const people = data.allStrapiPeople;
  return (
    <Layout>
      <SEO
        keywords={[`i-em`, `bayreuth`, `über uns`, `brink`, `alexander`]}
        title="Über Uns"
      />
      <section id="hero">
        <div className="container mx-auto">
          <div className="text-center my-8 mt-12">
            <h1 className="font-header text-4xl">Das sind wir!</h1>
            <h2>Infos über das iLab Ethik und Management Bayreuth</h2>
          </div>
          <div>
            {data.strapiUeberUns.ContentBlocks &&
              data.strapiUeberUns.ContentBlocks.map((block) => {
                return <BlockTextImage key={block.id} {...block} />;
              })}
          </div>

          <div
            id="team"
            className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-12"
          >
            <div className="">
              <h2 className="font-header text-4xl">Unser Team.</h2>
            </div>
            {people.edges.map(({ node }) => {
              if (node.Status?.Team == true) {
                return (
                  <div
                    key={node.eMail}
                    className="border-1 border-black"
                    style={{ border: "1px solid black" }}
                  >
                    <TeammemberDetails person={node} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>

          <div
            id="netzwerk"
            className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-12"
          >
            <div className="">
              <h2 className="font-header text-4xl">Unser Netzwerk.</h2>
            </div>
            {people.edges.map(({ node }) => {
              if (node.Status?.Netzwerk == true) {
                return (
                  <div
                    key={node.eMail}
                    className="border-1 border-black"
                    style={{ border: "1px solid black" }}
                  >
                    <TeammemberDetails person={node} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>

          <div
            id="EhemaligImTeam"
            className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-12"
          >
            <div className="">
              <h2 className="font-header text-4xl">
                Ehemalige Mitglieder.
              </h2>
            </div>
            {people.edges.map(({ node }) => {
              if (node.Status?.EhemaligImTeam == true) {
                return (
                  <div
                    key={node.eMail}
                    className="border-1 border-black"
                    style={{ border: "1px solid black" }}
                  >
                    <TeammemberDetails person={node} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>

          <div
            id="autoren"
            className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 mb-12"
          >
            <div className="">
              <h2 className="font-header text-4xl">Autor:innen.</h2>
            </div>
            {people.edges.map(({ node }) => {
              if (node.Status?.Autor == true) {
                return (
                  <div
                    key={node.eMail}
                    className="border-1 border-black"
                    style={{ border: "1px solid black" }}
                  >
                    <TeammemberDetails person={node} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    strapiUeberUns {
      ContentBlocks
    }
    allStrapiPeople {
      edges {
        node {
          Vorname
          Nachname
          AkademischeTitel
          Rolle
          eMail
          LinkedIn
          Xing
          Website
          Status {
            Autor
            Netzwerk
            Team
            EhemaligImTeam
          }
          Teamvita
          Profilbild {
            localFile {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default UeberPage;
