import React, { useState } from "react";
import Img from "gatsby-image";

import MailIcon from "../../images/mail.svg";
import LinkedinIcon from "../../images/linkedin.svg";
import XingIcon from "../../images/xing.svg";
import WebsiteIcon from "../../images/website.svg";

import * as styles from "./TeammemberDetails.module.scss";

const TeammemberDetails = ({ person }) => {
  const [popOverOpen, togglePopover] = useState(false);
  return (
    <div
      className={`${styles.autor} flex flex-col xl:flex-row p-4 items-center justify-left my-4 relative`}
    >
      {person.Teamvita && (
        <div
          className={`${popOverOpen ? styles.popoveropen : ""} ${
            styles.popover
          } shadow-lg break-words bg-primary text-white font-light`}
        >
          {person.Teamvita}
        </div>
      )}
      <div
        className="mb-4 mr-4 rounded-full bg-gray"
        style={{ width: 100, height: 100 }}
        onMouseEnter={() => togglePopover(true)}
        onMouseLeave={() => togglePopover(false)}
      >
        {person.Profilbild && (
          <Img
            style={{ width: 100, height: 100 }}
            className="cursor-pointer rounded-full w-100 h-100"
            fixed={person.Profilbild.localFile.childImageSharp.fixed}
          ></Img>
        )}
      </div>
      <div className="relative z-0">
        <h4 className="text-lg font-header">
          {person.AkademischeTitel} {person.Nachname}, {person.Vorname}
        </h4>
        <p>{person.Rolle}</p>
        <div
          className="flex justify-left space-x-2"
          style={{ marginLeft: "-3px" }}
        >
          {person.eMail && (
            <a
              className="opacity-75 hover:opacity-100"
              href={`mailto:${person.eMail}`}
            >
              <MailIcon />
            </a>
          )}
          {person.LinkedIn && (
            <a
              className="opacity-75 hover:opacity-100"
              href={`${person.LinkedIn}`}
              target="_blank"
            >
              <LinkedinIcon />
            </a>
          )}
          {person.Xing && (
            <a
              className="opacity-75 hover:opacity-100"
              href={`${person.Xing}`}
              target="_blank"
            >
              <XingIcon />
            </a>
          )}
          {person.Website && (
            <a
              className="opacity-75 hover:opacity-100"
              href={`${person.Website}`}
              target="_blank"
            >
              <WebsiteIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeammemberDetails;
